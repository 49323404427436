const greetings = {
  english: "Hello",
  spanish: "Hola",
  french: "Bonjour",
  amharic: "ሰላም",
  arabic: "مرحباً",
  chinese: "你好",
  hindi: "नमस्ते",
};

export default greetings;
